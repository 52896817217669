div.mu-checkbox {
  display: flex;
  cursor: pointer;

  &.disabled {
    color: var(--gray-9);
  }

  div.checkbox {
    svg {
      font-size: 20px;
    }
  }

  div.description {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
}
