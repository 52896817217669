button.mu-button {
  color: var(--ion-color-primary-contrast);
  background-color: var(--ion-color-primary);
  border: none;
  padding: 7px 56px;
  border-radius: 26px;
  margin-right: 20px;
  outline: none;
  cursor: pointer;

  &.disabled {
    background-color: #cccccc;
    border-color: #cccccc;
    color: white;
  }

  &.narrow {
    padding: 5px 10px;
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0;
  }

  &.primary-shade {
    background-color: var(--ion-color-primary-shade);
    border-color: var(--ion-color-primary-shade);
  }

  &.blank {
    color: var(--gray-3);
    background-color: transparent;
    border-color: transparent;
  }

  &.outline {
    color: var(--ion-color-primary);
    background-color: transparent;
    border: 1px solid var(--ion-color-primary);
  }
}
