div#mu-menu {
  background: white;

  a {
    text-decoration: none;

    div.mu-menu-item {
      margin: 5px 0 5px 67px;
      line-height: 37px;
      color: var(--c10);
      padding-left: 35px;
      height: 37px;

      &.selected {
        color: white;
        background: var(--c10);
        border-radius: 18.5px 0 0 18.5px;
      }

      svg {
        font-size: 18px;
        width: 20px;

        &:first-child {
          position: relative;
          top: -11px;
        }

        &.expansion {
          float: right;
          font-size: 12px;
          margin: 12px 10px 0 0;
        }
      }

      span {
        font-size: 16px;
        font-weight: 400;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 127px;
        position: relative;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }

  div.submenu {
    div.mu-menu-item {
      margin: 5px 0 5px 87px;
      line-height: 37px;
      color: var(--c3);
      padding-left: 35px;

      &.selected {
        color: white;
        background: var(--c3);
        border-radius: 18.5px 0 0 18.5px;
      }
    }
  }

  div#launch-class-wrapper {
    margin-left: 65px;
    margin-top: 10px;

    button {
      font-weight: 400;
    }
  }

  div#mu-menu-items-wrapper {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 120px;
    box-sizing: border-box;
  }

  div#final-links {
    position: absolute;
    bottom: 30px;
    right: 30px;
    text-align: right;

    a {
      color: var(--gray-8);
      text-decoration: underline;
      display: block;

      span {
        background: white;
        box-shadow: 0 0 5px white;
      }
    }
  }
}
