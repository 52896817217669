div#signup {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;

  div#color-gradient {
    height: 100%;
    background: linear-gradient(var(--c7), var(--c4));
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    flex-grow: 1;
  }

  div#content {
    padding: 0 135px;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 350px;

    img#logo {
      width: 109px;
    }

    > div.mu-input {
      width: 350px;
    }

    > div.error {
      color: var(--red);
    }

    > div.row {
      display: flex;
      flex-direction: row;
      text-align: left;

      > div {
        flex-grow: 1;
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  div#signup div#content {
    padding: 0 calc(50vw - 175px);
  }
}
