div.mu-radio {
  div.radio-button {
    cursor: pointer;
    text-align: left;
    margin-top: 10px;

    &.disabled {
      color: var(--gray-8);
    }

    svg {
      margin-right: 5px;
    }
  }
}
