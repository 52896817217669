div#chatbox {
  width: 320px;
  //box-shadow: 0 10px 15px rgba(5, 5, 5, 0.26);

  div.chatbox-header {
    display: flex;
    color: var(--c7);
    text-transform: uppercase;
    font-family: 'Unite', sans-serif;
    font-weight: 200;
    letter-spacing: 0.15em;
    line-height: 30px;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    img {
      width: 42px;
      margin-right: 5px;
      margin-bottom: -10px;
    }
  }

  div.chatbox-messages {
    height: 400px;
    overflow: auto;
    padding: 10px;

    div.message {
      //display: inline-block;
      border-radius: 10px;
      max-width: 200px;
      width: fit-content;
      font-size: 0.9em;
      color: black;
      padding: 10px;
      margin-bottom: 10px;

      &.response {
        background: var(--gray-c);
        margin-right: auto;
        border-bottom-left-radius: 0;
      }

      &.query {
        background: var(--c8);
        margin-left: auto;
        border-bottom-right-radius: 0;
      }
    }
  }

  div.chatbox-footer {
    display: flex;
    padding: 10px;

    > div {
      margin: 0;
    }

    button {
      height: 28px;
      margin-top: 2px;
      margin-left: 10px;
      padding-top: 3px;
    }
  }
}

@keyframes blink {
  from {
    background: black;
  }
  33% {
    background: black;
  }
  34% {
    background: var(--gray-6);
  }
  to {
    background: var(--gray-6);
  }
}

div.loading-ellipses {
  height: 7px;
  line-height: 0;

  > div {
    background: white;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 3px;
    animation-duration: 0.9s;
    animation-name: blink;
    animation-iteration-count: infinite;

    &.loading-ellipsis-2 {
      animation-delay: 0.3s;
    }

    &.loading-ellipsis-3 {
      animation-delay: 0.6s;
    }
  }
}
