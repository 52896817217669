@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';

body > iframe {
  display: none;
}

div#root {
  width: 100vw;
  height: 100vh;

  div.App {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

div.main-level {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 84px;
  top: 0;
  box-sizing: border-box;
  overflow: hidden;

  div#mu-menu {
    width: 300px;
    height: 100%;
    display: inline-block;
    border-right: 1px solid var(--gray-e1);
    box-sizing: border-box;
    z-index: 3;
    position: relative;
    background: white;
  }

  div.mu-content-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding-left: 300px;
    padding-top: 84px;
    box-sizing: border-box;
    overflow: hidden;
    background: var(--gray-f5);

    div.mu-content {
      position: relative;
      display: inline-block;
      flex-grow: 1;
      overflow: auto;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  div#root {
    div.mu-header-desktop {
      div.mu-header__inner {
        padding: 6px 20px;

        div#menu-toggle {
          display: block;
          font-size: 40px;
          margin-right: 10px;
        }

        img#mu-logo {
          height: 50px;
          margin-top: 5px;
          margin-bottom: -5px;
        }

        div.environment {
          font-size: 24px;
          line-height: 50px;
        }
      }
    }

    div.main-level {
      padding-top: 68px;

      div#mu-menu {
        background: white;
        width: 250px;

        &.retracted {
          transition: margin-left 0.5s;
          margin-left: -250px;
        }

        &.expanded {
          transition: margin-left 0.5s;
          margin-left: 0;
        }

        a {
          div.mu-menu-item {
            margin: 5px 0 5px 0;

            &.selected {
              border-radius: 0;
            }
          }
        }

        div#launch-class-wrapper {
          margin: 5px 20px;

          button {
            width: 100%;
          }
        }
      }

      div.mu-content-wrapper {
        padding-left: 0;
        padding-top: 68px;
      }
    }
  }
}

li {
  margin-top: 10px;

  &.subpoint {
    margin-left: 20px;
    list-style-type: circle;
    margin-top: 0;
  }
}

code {
  border: 1px solid var(--gray-b);
  background: var(--gray-e);
  border-radius: 5px;
  font-family: monospace;
  color: var(--gray-4);
  padding: 0 3px 0 5px;
}

// Font size classes; e.g. mu-text-sm-bold, mu-text-lg
[class*='mu-text'] {
  font-weight: normal;
}

[class*='mu-text'][class*='light'] {
  font-weight: 400;
}

[class*='mu-text'][class*='bold'] {
  font-weight: 600;
}

[class*='mu-text'][class*='bolder'] {
  font-weight: 700;
}

[class*='mu-text-sm'] {
  font-size: 11px;
  line-height: 15px;
}

[class*='mu-text-md'] {
  font-size: 14px;
  line-height: 19px;
}

[class*='mu-text-md-lg'] {
  font-size: 18px;
  line-height: 24px;
}

[class*='mu-text-lg'] {
  font-size: 24px;
  line-height: 33px;
}

[class*='mu-text'][class*='block'] {
  display: block;
}

.margin-top {
  margin-top: 20px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-left {
  margin-left: 20px;
}

.margin-right {
  margin-right: 20px;
}

div.page {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
  position: relative;

  div.page-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 10px;
    display: inline-block;
  }

  .page-actions {
    position: absolute;
    right: 20px;
    top: 23px;
    z-index: 2;
  }

  div.page-box {
    border: 1px solid var(--gray-e1);
    background: white;
    padding: 30px;
    margin-bottom: 20px;
  }
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.alight-items-start {
  align-items: start;
}

.alight-items-center {
  align-items: center;
}

.alight-items-end {
  align-items: end;
}

.justify-content-left {
  justify-content: left;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-right {
  justify-content: right;
}

.full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.red {
  color: var(--red);
}

span.link {
  cursor: pointer;
  text-decoration: underline;
  color: #0000ee;
}

.ReactModal__Content.ReactModal__Content--after-open {
  max-height: calc(100vh - 80px);
  overflow: auto;
}

div.MuiTooltip-popper {
  z-index: 100000001;
}

svg.fa-check {
  color: var(--green);
}

svg.fa-times {
  color: var(--red);
}

svg.fa-trash {
  color: var(--red);
  cursor: pointer;
}

svg.fa-edit {
  cursor: pointer;
}

div.tag {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 0 6px 3px;
  background-color: var(--c2);
  color: var(--c7);
  border: 3px solid;
  border-radius: 5px;
  font-size: 14px;

  svg {
    color: inherit;
    cursor: pointer;
    vertical-align: middle;
  }
}

.MuiTooltip-tooltip {
  white-space: pre-line;
}
