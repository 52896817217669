div.mu-alert {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid;
  border-radius: 8px;
  box-sizing: border-box;

  svg.close {
    float: right;
    margin: 4px 5px;
    cursor: pointer;
  }
}
