div.mu-textarea {
  width: 100%;
  margin-top: 13px;
  text-align: left;

  &.no-margin {
    margin-top: 0;
  }

  span.mu-input-label {
    display: block;

    svg.fa-info {
      vertical-align: baseline;
      cursor: help;
    }
  }

  textarea {
    width: 100%;
    margin-top: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 10px;
    border-radius: 9px;
    border: 0.5px solid var(--gray-d4);
    box-sizing: border-box;
    box-shadow: 0 0 10px transparent;
    resize: none;

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 10px rgba(54, 202, 212, 0.25);
    }

    &.error {
      outline: none;
      box-shadow: 0 0 10px rgba(255, 0, 0, 0.25);
    }
  }

  span.mu-input-error-message {
    color: var(--red);
    display: block;
  }

  div.char-counter {
    text-align: right;
    font-weight: 100;
    font-size: 12px;
  }
}
