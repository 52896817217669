div.mu-header-desktop {
  background: white;
  border-bottom: 1px solid var(--gray-e1);
  z-index: 3;
  position: relative;

  div.mu-header__inner {
    padding: 6px 80px;
    display: flex;
    align-items: center;

    &.dev {
      background-color: var(--ion-color-tertiary-shade);
      color: white;
    }

    &.test {
      background-color: var(--ion-color-success-tint);
    }

    &.qa {
      background-color: var(--ion-color-secondary);
    }

    div#menu-toggle {
      display: none;
    }

    div.environment {
      color: white;
      font-size: 36px;
      line-height: 71px;
    }

    img.mu-logo {
      height: 66px;
    }

    div.icon-wrapper {
      height: 40px;
      display: flex;
      align-items: center;
      position: relative;

      div.count {
        display: block;
        background: var(--red);
        padding: 0 4px;
        border-radius: 50px;
        color: white;
        position: absolute;
        top: 2px;
        right: 0;
        z-index: 1;
        font-size: 10px;
      }
    }

    .mu__user-ctrl {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      div.unread-count {
        display: inline-block;
        background: var(--red);
        padding: 2px 8.1px;
        border-radius: 50px;
        color: white;
        font-size: 12px;
      }

      .mu__user-ctrl__username {
        margin-right: 20px;
      }

      .mu__user-ctrl__sub {
        position: absolute;
        top: 60px;
        right: 0;
        background: white;
        color: black;
        box-sizing: border-box;
        padding: 0;
        border: 1px solid var(--gray-d);
        border-top: 0;
        display: flex;
        flex-direction: column;

        > div {
          padding: 10px;

          &:hover {
            cursor: pointer;
            background: var(--gray-e);
          }
        }
      }
    }
  }
}
