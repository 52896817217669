div.mu-input {
  width: 100%;
  margin-top: 13px;
  text-align: left;

  &.no-margin {
    margin-top: 0;
  }

  span.mu-input-label {
    display: block;

    svg.fa-info {
      vertical-align: baseline;
      cursor: help;
    }
  }

  .mu-input-line {
    display: flex;

    svg.icon-before {
      background: var(--ion-color-primary);
      color: white;
      margin-top: 2px;
      padding: 6px 7px;
      margin-right: -9px;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      cursor: pointer;
      z-index: 1;
    }

    input {
      width: 100%;
      margin-top: 2px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 5px 10px;
      border-radius: 9px;
      border: 0.5px solid var(--gray-d4);
      box-sizing: border-box;
      box-shadow: 0 0 10px transparent;

      &:focus-visible {
        outline: none;
        box-shadow: 0 0 10px rgba(54, 202, 212, 0.25);
      }

      &.error {
        outline: none;
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.25);
      }

      &[type='range'] {
        appearance: auto;
        padding: 0;

        &.error {
          box-shadow: none;
        }
      }
    }

    svg.icon-after {
      background: var(--ion-color-primary);
      color: white;
      margin-top: 2px;
      padding: 6px 7px;
      margin-left: -9px;
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
      cursor: pointer;
    }
  }

  span.mu-input-error-message {
    color: var(--red);
    display: block;
  }
}
