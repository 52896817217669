div.user-photo {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: lightgray;
  border-radius: 50%;
  position: relative;
  font-size: 15px;
  line-height: 30px;

  span.initials {
    width: 100%;
    text-align: center;
    position: absolute;
    background: none;
    font-size: 100%;
    color: var(--gray-8);
    font-weight: lighter;
    left: 0;
    top: 0;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
  }
}
