div.mu-image-card {
  background: white;
  display: flex;
  border: 1px solid var(--gray-e1);

  div.image {
    img {
      width: 100%;
      height: 100%;
    }
  }

  div.content {
    padding: 15px;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
}
