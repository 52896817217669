div.mu-autocomplete-wrapper {
  height: 49px;
  margin-top: 13px;

  div.mu-autocomplete {
    z-index: 1;
    position: relative;

    &.open {
      z-index: 10000000001;
      position: absolute;
    }

    .mu-input {
      z-index: 2;
      position: relative;
    }

    .results {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      position: absolute;
      width: 100%;
      background: white;
      margin-top: -20px;
      padding-top: 24px;
      z-index: 1;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      border: 0.5px solid var(--gray-d4);
      box-sizing: border-box;

      .no-results,
      .mu-loading {
        padding: 5px;
      }

      .search-results {
        .search-result {
          cursor: pointer;

          &:hover,
          &.hovered {
            background: #e4e4e4;
          }
        }
      }
    }
  }
}
