div.mu-card {
  background: white;
  padding: 15px;
  border: 1px solid var(--gray-e1);
  margin-bottom: 20px;

  div.card-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 10px;
  }
}
