div.mu-table-wrapper {
  div.mu-table-mobile {
    display: none;
  }

  table.mu-table {
    width: 100%;

    thead {
      tr {
        th {
          text-align: left;
          padding: 8px 12px;
          vertical-align: top;

          svg.fa-filter {
            float: right;
            cursor: pointer;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(2n + 1) {
          background: var(--gray-e);
        }

        &:hover {
          background: var(--gray-d);
        }

        td {
          padding: 8px 12px;
        }

        td.edit {
          padding: 0;

          div.mu-input {
            margin: 0;

            input {
              margin: 0;
              padding: 8px 12px;
              border-radius: 0;
              border: none;
              background: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  div.mu-table-wrapper {
    div.mu-table-mobile {
      display: block;

      div.mu-table-row {
        div.row-header {
          cursor: pointer;

          div.header {
            padding: 8px 12px;
          }

          div.expansion {
            padding: 8px 12px;
          }
        }

        div.row-content {
          div.column {
            padding: 8px 12px;

            div.column-title {
              font-weight: bold;
            }

            div.column-content {
            }
          }
        }

        hr {
          margin: 0 12px;
        }
      }
    }

    table.mu-table {
      display: none;
    }
  }
}
