body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;

    --content-width: 1200px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Raleway', sans-serif;
}

/* Light component library */
.mu__link-button {
    color: var(--gray-8);
    cursor: pointer;
    background: white;
    border: 0;
    padding: 0;
    margin: 0;
}

.mu__link-button i span {
    margin-left: 10px;
}

.mu__link-button:hover {
    color: var(--blue);
}

.flex-space {
    display: flex;
    flex-grow: 1;
    min-width: 15px;
}

/*div#root button:not(.mu-button),*/
/*.mu-button-old {*/
/*  font-family: 'Source Sans Pro', 'Segoe UI', 'Droid Sans', 'Tahoma', 'Arial', sans-serif;*/
/*  font-size: 1rem;*/
/*  font-weight: 700;*/
/*  text-transform: uppercase;*/
/*  background-color: #0095d9;*/
/*  padding: 1rem 2rem;*/
/*  display: inline-block;*/
/*  vertical-align: middle;*/
/*  margin: 0;*/
/*  padding: 0.85em 1em;*/
/*  border: 1px solid transparent;*/
/*  border-radius: 0;*/
/*  transition: background-color 0.25s ease-out, color 0.25s ease-out;*/
/*  font-family: inherit;*/
/*  font-size: 0.9rem;*/
/*  -webkit-appearance: none;*/
/*  line-height: 1;*/
/*  text-align: center;*/
/*  cursor: pointer;*/
/*  background-color: #1779ba;*/
/*  color: var(--gray-f);*/
/*  border-radius: 3px !important;*/
/*}*/

div.ReactModalPortal {
    z-index: 100000000;
    position: relative;
}

div.ReactModal__Overlay.ReactModal__Overlay--after-open {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.ReactModal__Content.ReactModal__Content--after-open {
    border: none !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 600px) {
    div.ReactModal__Content.ReactModal__Content--after-open > div {
        min-width: unset !important;
    }
}

@media only screen and (min-width: 601px) {
    div.ReactModal__Content.ReactModal__Content--after-open {
        border-radius: 30px !important;
        inset: unset !important;
        padding: 30px !important;
    }
}

hr {
    border-style: solid;
    border-top: none;
    border-color: #d0d0d0;
}

input {
    background-color: var(--white);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Noto Sans', Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 0.875rem;
    line-height: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    height: auto;
    color: var(--gray-30);
    box-shadow: inset 0 0 0 1px #868686;
    border-style: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
