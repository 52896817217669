div.progress-bar {
  width: 100%;
  height: 11px;
  border-radius: 30px;
  background-color: #dfdfdf;
  display: flex;
  font-size: 14px;
  text-align: center;

  div.progress {
    height: 100%;
    background-color: var(--ion-color-secondary);
    border-radius: inherit;
    color: white;
  }
}
