div.mu-user-autocomplete-wrapper {
  height: 49px;
  margin-top: 13px;
  min-width: 280px;

  div.mu-user-autocomplete {
    z-index: 1;
    position: relative;
    color: black;

    &.open {
      z-index: 10000000001;
      position: absolute;
    }

    .mu-input {
      z-index: 2;
      position: relative;
    }

    .results {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      position: absolute;
      width: 100%;
      background: white;
      margin-top: -20px;
      padding-top: 24px;
      z-index: 1;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      border: 0.5px solid var(--gray-d4);
      box-sizing: border-box;
      max-height: 250px;
      overflow: auto;

      .no-results,
      .mu-loading {
        padding: 5px;
      }

      .search-results {
        .search-result {
          cursor: pointer;
          padding: 10px;

          &:hover,
          &.hovered {
            background: #e4e4e4;
          }

          div.user-display {
            margin: 0;
          }
        }
      }
    }
  }
}
