div.user-display {
  margin-bottom: 20px;

  div.user-name {
    display: inline-block;
    margin-left: 15px;
    width: 190px;

    span {
      display: block;
    }
  }

  svg.fa-times {
    font-size: 24px;
    vertical-align: top;
    margin-top: 6px;
    font-weight: 100;
    cursor: pointer;
  }
}
