div.mu-tabs-container {
  div.mu-tabs {
    margin-bottom: 15px;

    div.mu-tab {
      display: inline-block;
      margin-right: 30px;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      padding-bottom: 5px;

      &.selected {
        border-bottom: 3px solid #22bbff;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  div.mu-tabs-mobile {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  div.mu-tabs-container.mobile-friendly {
    div.mu-tabs {
      display: none;
    }

    div.mu-tabs-mobile {
      display: block;

      div.mu-select {
        margin: 0 0 20px;
        display: inline-block;
      }
    }
  }
}
